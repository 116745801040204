import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center ml-3 mr-4"},'div',attrs,false),on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c(VIcon,[_vm._v("mdi-bell")])],1)])])]}}])},[_c('div',[_c(VCard,{staticClass:"card-container"},[_c('div',[_c('b',{staticClass:"notification-title"},[_vm._v("Notification ("+_vm._s(_vm.notifLength)+")")]),_c('div',{staticClass:"ml-2 mr-2",staticStyle:{"height":"250px","overflow":"auto"}},_vm._l((_vm.listNotif),function(notif,index){return _c('div',{key:index,staticClass:"d-flex align-center justify-space-evenly notification-item",on:{"click":function($event){return _vm.gotoRoute(notif, index)}}},[(notif.read)?_c(VIcon,{staticClass:"notification-icon",attrs:{"color":"grey","large":""}},[_vm._v("mdi-email-open")]):_c(VIcon,{staticClass:"notification-icon",attrs:{"color":"grey","large":""}},[_vm._v("mdi-email")]),_c('div',[_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(notif.message))]),_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(notif.notifDate)+" ")])])],1)}),0)]),_c('div',{staticClass:"divider"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }