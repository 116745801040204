//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { bookIcon, alertIcon } from "@debionetwork/ui-icons";
import getEnv from "@/utils/env";
export default {
  name: "NavigationDrawer",
  props: {
    width: String
  },
  data: () => ({
    bookIcon,
    alertIcon,
    version: "",
    drawerButtons: [{
      text: "Orders",
      auth: true,
      route: {
        name: "lab-dashboard"
      }
    }, {
      text: "Account",
      auth: false,
      route: {
        name: "lab-dashboard-account"
      }
    }, {
      text: "Services",
      auth: false,
      route: {
        name: "lab-dashboard-services"
      }
    }, {
      text: "Order History",
      auth: false,
      route: {
        name: "lab-dashboard-order-history"
      }
    }]
  }),
  watch: {
    $route(val) {
      this.setActiveMenu(val.name);
    }
  },
  methods: {
    goLink(route) {
      if (route != undefined && route.name != this.$route.name) {
        this.$router.push(route);
      }
    },
    setActiveMenu(value) {
      this.drawerButtons = this.drawerButtons.map(btn => {
        var _btn$route;
        return {
          ...btn,
          active: ((_btn$route = btn.route) === null || _btn$route === void 0 ? void 0 : _btn$route.name) === value
        };
      });
    }
  },
  created() {
    this.setActiveMenu(this.$route.name);
    this.version = "v".concat(getEnv("VUE_APP_VERSION"));
  },
  computed: {
    ...mapState({
      labAccount: state => state.substrate.labAccount
    }),
    computeDisabledCondition() {
      return this.drawerButtons.map(btn => {
        var _this$labAccount;
        if (btn.text === "Orders" || btn.text === "Customer Care") return {
          ...btn
        };
        return {
          ...btn,
          auth: ((_this$labAccount = this.labAccount) === null || _this$labAccount === void 0 ? void 0 : _this$labAccount.verificationStatus) === "Verified" ? true : false
        };
      });
    }
  }
};