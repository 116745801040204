//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
export default {
  name: "HeaderUserInfo",
  computed: {
    ...mapState({
      wallet: state => state.substrate.wallet,
      labAccount: state => state.substrate.labAccount
    }),
    computedAvatar() {
      var _this$labAccount$info;
      return (_this$labAccount$info = this.labAccount.info.profileImage) !== null && _this$labAccount$info !== void 0 ? _this$labAccount$info : require("@/assets/user-profile-sample.png");
    }
  },
  data: () => ({
    name: ""
  }),
  watch: {
    labAccount(val) {
      var _val$info$name;
      this.name = (_val$info$name = val.info.name) !== null && _val$info$name !== void 0 ? _val$info$name : "";
    }
  },
  created() {
    var _this$labAccount$info2;
    this.name = (_this$labAccount$info2 = this.labAccount.info.name) !== null && _this$labAccount$info2 !== void 0 ? _this$labAccount$info2 : "";
  }
};