import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    items() {
      if (this.$route.meta && this.$route.meta.breadcrumbs) {
        console.log(this.$route.meta.breadcrumbs[0]);
        return this.$route.meta.breadcrumbs;
      }
      return [];
    }
  },
  methods: {
    /**
     * createHref
     * 
     * resolve the parameters in href if any
     */
    createHref(href) {
      const {
        params
      } = this.$route;
      const paramKeys = Object.keys(params);
      let _href = href;
      paramKeys.forEach(key => {
        _href = _href.replace(":".concat(key), params[key]);
      });
      return _href;
    }
  }
};