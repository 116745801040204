//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import v from "voca";
import { mapState, mapGetters } from "vuex";
import Breadcrumbs from "@/views/Dashboard/Breadcrumbs";
// import MenuChangeRole from "@/components/MenuChangeRole";
import HeaderUserInfo from "@/components/HeaderUserInfo";
import NavigationDrawer from "@/components/NavigationDrawer";
import HeaderNotification from "@/components/HeaderNotification";
export default {
  name: "DashboardDoctor",
  components: {
    Breadcrumbs,
    NavigationDrawer,
    // MenuChangeRole,
    HeaderUserInfo,
    HeaderNotification
  },
  data: () => ({
    show: false,
    dialogAlert: false,
    alertTextBtn: "Continue",
    imgWidth: "270"
  }),
  mounted() {
    console.log("Is pair locked?", this.pair.isLocked);
    this.show = this.pair.isLocked;
  },
  computed: {
    ...mapGetters({
      pair: "substrate/wallet"
    }),
    ...mapState({
      isDoctorAccountExist: state => state.substrate.isDoctorAccountExist,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData
    }),
    isDoctor() {
      return this.$route.path.indexOf("doctor") > 0;
    },
    isDoctorDashboard() {
      return this.$route.path === "/doctor" || this.$route.path === "/doctor/";
    },
    pageHeader() {
      return this.$route.meta.pageHeader ? this.$route.meta.pageHeader : v.titleCase(this.$route.name);
    }
  },
  watch: {
    lastEventData() {
      if (this.lastEventData != null) {
        this.$store.dispatch("substrate/addListNotification", {
          address: this.wallet.address,
          event: this.lastEventData,
          role: "doctor"
        });
      }
    }
  },
  methods: {
    toggle() {
      this.show = false;
    },
    goToDashboard() {
      this.$router.push({
        path: "/doctor"
      });
    }
  }
};