//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { setReadNotification } from "@/lib/api";
let timeout;
export default {
  name: "HeaderNotification",
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      localListNotification: state => state.substrate.localListNotification
    })
  },
  props: {
    role: String
  },
  data: () => ({
    isLoading: false,
    balance: 0,
    name: "",
    listNotif: [],
    notifLength: 0,
    showDialog: false
  }),
  methods: {
    ...mapActions({
      clearAuth: "auth/clearAuth"
    }),
    async getListNotification() {
      await this.$store.dispatch("substrate/getListNotification", {
        address: this.wallet.address,
        role: this.role
      });
      this.setData();
    },
    setData() {
      this.listNotif = this.localListNotification;
      this.notifLength = this.listNotif.filter(x => x.read == false).length;
    },
    async gotoRoute(notif, index) {
      if (this.listNotif[index].read == false) {
        clearTimeout(timeout);
        this.listNotif[index].read = true;
        timeout = setTimeout(async () => {
          await setReadNotification(notif.notifId);
        }, 2000);
        this.$store.dispatch("substrate/updateDataListNotification", {
          address: this.wallet.address,
          role: this.role,
          data: this.listNotif
        });
      }
      // TODO: Remove this logic
      // if (this.$route.params.number === notif.params.number) return

      this.$router.push({
        name: notif.route,
        params: notif.params
      });
    }
  },
  watch: {
    localListNotification() {
      this.setData();
    }
  },
  async mounted() {
    await this.getListNotification();
  }
};