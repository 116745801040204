import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{staticClass:"mr-10 pt-10 dg-raleway-font",attrs:{"app":"","clipped":"","fixed":"","permanent":"","width":this.width}},[_c(VToolbar,{staticClass:"text-h7 secondary--text ",staticStyle:{"margin-top":"220px"},attrs:{"flat":""}},[_c(VFlex,[_vm._l((_vm.computeDisabledCondition),function(item,key){return _c(VContainer,{key:key,staticClass:"pt-1 pb-1"},[_c(VBtn,{class:item.active ? 'font-weight-bold sidebar-text primary--text' : 'font-weight-bold sidebar-text',attrs:{"text":"","disabled":!item.auth},on:{"click":function($event){return _vm.goLink(item.route)}}},[_c('span',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.text)+" "),(item.subText)?_c('p',{staticClass:"text-caption font-italic mb-0 black--text"},[_vm._v(_vm._s(item.subText))]):_vm._e()])])],1)}),_c(VDivider,{staticClass:"lineDivider"}),_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"footerDrawer"},[_c('a',{staticClass:"d-flex",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://docs.debio.network/","target":"_blank","rel":"noreferrer noopener nofollow"}},[_c('ui-debio-icon',{staticClass:"btnDrawerIcon",attrs:{"icon":_vm.bookIcon,"size":"16","stroke":"stroke","color":"#757274","viewBox":"0 0 18 18"}}),_c('span',[_vm._v("Customer Care")])],1),_c('a',{staticClass:"mt-3 d-flex",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://docs.debio.network/legal/terms-and-condition","target":"_blank","rel":"noreferrer noopener nofollow"}},[_c('ui-debio-icon',{staticClass:"btnDrawerIcon",attrs:{"icon":_vm.alertIcon,"size":"16","stroke":"stroke","color":"#757274"}}),_c('span',[_vm._v("Terms and Condition")])],1),_c('div',{staticClass:"version mt-16 mb-10 d-flex"},[_c('span',{staticClass:"mr-12"},[_vm._v("DeBio Network")]),_c('span',[_vm._v(_vm._s(_vm.version))])])])])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }